import React from "react"
import Obfuscate from "react-obfuscate";
import { Accordion, Card, Container, Button } from "react-bootstrap";

import { graphql } from "gatsby";
import Img from "gatsby-image";

import FullLayout from "../components/FullLayout";
import Head from "../components/Head";
//import EmbeddedGoogleForm from "../components/EmbeddedGoogleForm";

const IndexPage = props => (
  <FullLayout pageInfo={{ pageName: "index" }}>
    <Head title="Nova prilika za rad u fabrikama za preradu ribe u sezoni 2020!" description="Nova prilika za rad u fabrikama za preradu ribe u sezoni 2020! Ukoliko ste spremni na odricanja, savesni, vredni i prilagodljivi, iskoristite priliku i prijavite se za avanturu koja vam pruža fantastične mogućnosti za zaradu!!!" keywords={[`aljaska`, `posao`, `2020`, `H-2B`, `fishery`]} />

    <div className="content__header">
    </div>

    <div className="content__body">
      <div className="container--style container--default">
        <Container>
          <h1 className="heading">Nova prilika za rad u fabrikama za preradu ribe u sezoni 2020!</h1>
          <p> Ukoliko ste spremni na odricanja, savjesni, vrijedni i prilagodljivi, iskoristite priliku i prijavite se za avanturu koja vam pruža fantastične mogućnosti za zaradu!!!</p>
          <p>Rad u kompanijama za preradu ribe može biti naporan, ali u isto vrijeme može predstavljati i jedinstveno iskustvo sa odličnim mogućnostima za zaradu. Broj radnih sati tokom dana ili tokom radne nedelje zavisi od količine ribe koja se ulovi i koja je dostupna za preradu. Ukoliko je ulov ribe zadovoljavajući moguće je raditi i po 16 sati dnevno.</p>
          <Img fluid={props.data.aljaska1.childImageSharp.fluid} alt="Aerodrom Nikola Tesla 2019" />
          <p></p>
          <h3 className="heading">Radno okruženje čine hladni i vlažni uslovi, a kompanije obezbjeđuju</h3>
          <ul>
            <li>Adekvatnu opremu za rad</li>
            <li>Šansu za mnogo prekovremenih sati koji donose zaradu 50% više od regularnih sati</li>
            <li>Mogućnost napredovanja unutar organizacije</li>
            <li>Transport od Portlanda, Sijetla ili Enkoridža do fabrike i natrag (nakon uspješno završene sezone)</li>
          </ul>
        </Container>
      </div>
      <div className="container--style container--alternate">
        <Container>
          <h2 className="heading" id="accommodation">Smještaj</h2>
          <p>Smeštaj je kolektivnog tipa sa odvojenim ženskim i muškim dijelom na bliskoj udaljenosti od fabrike, a kantina je odmah uz fabriku.
            Zbog specifičnosti posla i prirodnih faktora koji utiču na ovu industriju nemoguće je unaprijed precizirati tačan period realizacije programa a obzirom da sarađujemo sa više kompanija potrebe poslodavaca za sezonskim radnicima variraju najčešće za period od 3 do 10 meseci. Širu diskusiju oko perioda plasmana vodićemo sa učesnicima koji budu ušli u uži izbor agencije na orjentaciji pre upisivanja na program.</p>
        </Container>
      </div>
      <div className="container--style container--inverted">
        <Container>
          <h2 className="heading" id="qualifications">Potrebne Kvalifikacije</h2>
          <ul>
            <li>Osobe koje su punoljetne</li>
            <li>Osobe koje su u stalnom radnom odnosu od najmanje godinu dana kod trenutnog poslodavca i u mogućnosti su da dobiju odsustvo sa posla tokom trajanja programa ili su vlasnici firmi koje posluju najmanje godinu dana</li>
            <li>Dobra psihofizička sprema</li>
            <li>Nema preferencija u smislu muških/ženskih učesnika</li>
            <li>Znanje engleskog za nesmetanu komunikaciju i rad (minimum osnovno znanje)</li>
          </ul>
        </Container>
      </div>
      <div className="container--style container--standard">
        <Container>
          <Img fluid={props.data.aljaska.childImageSharp.fluid} alt="Aljaska 2020 novi konkurs" />
          <p></p>
          <h2 className="heading">Ostale informacije</h2>
          <p>Dobra vijest za sve učesnike ovog programa. I u sezoni 2020, kao i u prethodnim sezonama, poslodavci će učestvovati u troškovima dolaska svojih učesnika. Svim učesnicima programa nakon uspešno završene prve polovine programa biće refundirana polovina troškova za avionsku kartu i vizu, a druga polovina troškova avionske karte i viziranja će biti refundirana nakon uspešno završenog programa.</p>
          <p>Za sve učesnike koje agencija DREAM BIG primi na program biće organizovan Job Fair sa predstavnicima kompanija za koju budu raspoređeni i isti će se održati u narednom periodu u Beogradu.</p>
          <p>Oni koji budu bili selektovani od strane agencije biće sukcesivno pozivani na orijentaciju agencije koja će biti održana u Podgorici u skladu sa potrebama poslodavaca kako za zimsku tako i za ljetnju sezonu, a najkasnije u roku od 120 dana od momenta prijavljivanja. Napominjemo da će odgovor od strane agencije dobiti samo učesnici koji su selektovani.</p>
          <p>Za prijavljivanje je potrebno nekoliko minuta a nakon uspešno popunjene prijave na email adresu sa kojom ste se prijavili će doći potvrda u vidu email-a sa odgovorima koje ste upisali u svojoj prijavi. Ukoliko u roku od 24h ne dobijete potvrdu prijave na email, obratite nam se putem email-a <Obfuscate email="drambigmne@gmail.com" /> i rado ćemo provjeriti da li je Vaša prijava registrovana.</p>
          <p>Molimo sve zainteresovane da prilikom prijavljivanja provjere sve podatke koje su unijeli kao i da prijavu ne popunjvaju više puta. Napominjemo da će sve duple prijave biti diskvaifikovane, kao i prijave više razlišitih osoba koje su koristile istu email adresu.</p>
        </Container>
      </div>
      <div className="container--style container--standard">
        <Container>
          <h2 className="heading" id="faq">Često postavljana pitanja</h2>
          <Accordion defaultActiveKey="0">
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                  Šta se najčešće radi u fabrikama ribe?
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <p>Ove fabrike prerađuju ribu od momenta kada ista stigne u fabriku pa sve dok ne izađe prerađena bilo da je smrznuta ili konzervirana. U tom smislu učesnici najčešće obavljaju poslove vezane za sortiranje ribe, sječenje, čišćenje, odvajanje ikre, filetiranje, pakovanje, utovar upakovanih proizvoda u hladnjače, konzerviranje i slično.</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                  Koliko se radi i od čega zavisi koliko će posla biti?
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <p>Rad je direktno povezan sa potrebama kompanija a najvažniji faktor koji definiše potrebe kompanija je količina ribe koja bude dostupna za preradu. Nije moguće predjvideti koliko će ribe biti dostupno za preradu a faktori koji mogu uticati na to su vremenske prilike, migracija ribe kao i moguće zabrane ribolova u određenim momentima radi očuvanja biološke ravnoteže.</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                  Šta je prekovremeni rad?
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <p>Za prekovremeni rad smatra se svaki sat nakon odrađenih 40 sati u jednoj radnoj nedjelji ili svaki sat nakon odrađenih 8 sati ukoliko se gleda na dnevnom nivou.</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                  Po čemu se prekovremeni rad razllikuje od regularnog rada?
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  <p>Prekovremeni rad se plaća 50% više od regularnog rada (Primera radi ako je regularan sat plaćen 12$ po satu, prekovremeni rad će biti plaćen 18$ po satu).</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="4">
                  Da li je ovo muški ili ženski posao?
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="4">
                <Card.Body>
                  <p>U fabrikama postoje i muške i ženske pozicije.</p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Container>
      </div>
      <div className="container--style container--standard">
        <Container>
          <h2 id="partners" className="heading">Naši partneri</h2>
          <a href="https://www.unitedworkandtravel.rs/" target="_blank" rel="noopener noreferrer" className="link-logo">
            <Img fluid={props.data.partnerLogo.childImageSharp.fluid} alt="United Work & Travel Partner Logo" />
          </a>
        </Container>
      </div>
    </div>

    <div className="content__footer">
    </div>

  </FullLayout>
);

export default IndexPage;

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1110) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const pageQuery = graphql`
  query {
    aljaska: file(relativePath: {eq: "aljaska.jpg"}) {
      ...fluidImage
    }
    aljaska1: file(relativePath: {eq: "alaska-1.jpg"}) {
      ...fluidImage
    }
    partnerLogo: file(relativePath: {eq: "partner-logo.png"}) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
